import { createSlice, Reducer } from '@reduxjs/toolkit';
import {
  addScorecardsCases,
  addCareerMentorOptionsCases,
} from 'store/scorecards/index';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import {
  storeName,
  sharedScorecardInitialState,
  initialFilters,
} from './config';

export * from './interfaces';

const sharedScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(sharedScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      const newState = cloneDeep(sharedScorecardInitialState);
      newState.filters = state.filters;
      Object.assign(state, newState);
    });
    addCareerMentorOptionsCases(builder, storeName);
    addScorecardsCases(builder, storeName, cloneDeep(initialFilters));
  },
});

export default sharedScorecards.reducer as Reducer<
  typeof sharedScorecardInitialState
>;
