import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { API_SCORECARD_FULL_INFO, API_SCORECARD_OWN } from 'constants/api';
import { Scorecard, ScorecardStores } from 'store/interfaces';
import { useScorecardPermissionsFroStatusGetter } from 'hooks';
import {
  ownScorecards,
  directScorecards,
  subordinatesScorecards,
  departmentScorecards,
  updateFullData,
  sharedScorecards,
} from 'store/scorecards';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { AxiosError } from 'axios';
import { useAppDispatch } from 'store';

export default function useEditScorecardPageData(
  isPageAccessible: boolean,
  variant: ScorecardStores,
) {
  const dispatch = useAppDispatch();
  const { scorecardId } = useParams();
  const navigate = useNavigate();
  const parsedScorecardId = +scorecardId!;
  const [scorecard, setScorecard] = useState<Scorecard>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [hasAccessForThisStatus, setHasAccessForThisStatus] =
    useState<boolean>(true);
  const permissionsForStatusGetter = useScorecardPermissionsFroStatusGetter();

  const getFullInfoUrl = (context: string) =>
    `${generateURL(API_SCORECARD_FULL_INFO, {
      id: +scorecardId!,
    })}?${qs.stringify({ context })}`;

  const URLS = {
    [ownScorecards]: `${API_SCORECARD_OWN}/${parsedScorecardId}`,
    [directScorecards]: getFullInfoUrl('direct-subordinates'),
    [subordinatesScorecards]: getFullInfoUrl('all-subordinates'),
    [departmentScorecards]: getFullInfoUrl('department'),
    [sharedScorecards]: getFullInfoUrl('shared'),
  };

  const URL = URLS[variant];

  useEffect(() => {
    setLoading(true);

    dispatch(updateFullData({ id: parsedScorecardId, store: variant }))
      .unwrap()
      .then((data) => {
        const { canEdit } = permissionsForStatusGetter(data.status);
        if (!canEdit) {
          setHasAccessForThisStatus(false);
        }
        setScorecard(data);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 403) {
          setHasAccessForThisStatus(false);
          return;
        }
        setNotFound(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    URL,
    dispatch,
    parsedScorecardId,
    permissionsForStatusGetter,
    setLoading,
    variant,
  ]);

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    scorecard,
    loading,
    notFound,
    onBackClick,
    hasAccess: isPageAccessible && hasAccessForThisStatus,
  };
}
