import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

const DirectReportsComponent = lazy(() => import('./DirectReports.component'));
const SubordinatesComponent = lazy(() => import('./Subordinates.component'));
const SharedScorecardsComponent = lazy(
  () => import('./SharedScorecards.component'),
);
const InactiveEmployeesComponent = lazy(
  () => import('./InactiveEmployees.component'),
);
const DeletedScorecardsComponent = lazy(
  () => import('./DeletedScorecards.component'),
);

export function DirectReports(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <DirectReportsComponent {...props} />
    </Suspense>
  );
}

export function Subordinates(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <SubordinatesComponent {...props} />
    </Suspense>
  );
}

export function SharedScorecards(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <SharedScorecardsComponent {...props} />
    </Suspense>
  );
}

export function InactiveEmployees(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <InactiveEmployeesComponent {...props} />
    </Suspense>
  );
}

export function DeletedScorecards(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <DeletedScorecardsComponent {...props} />
    </Suspense>
  );
}
