import {
  SCORECARD_STATUS_ACTIVE,
  SCORECARD_STATUS_COMPLETED,
  SCORECARD_STATUS_DELETED,
  SCORECARD_STATUS_FEEDBACK_COLLECTED,
  SCORECARD_STATUS_FEEDBACK_REQUESTED,
  SCORECARD_STATUS_GOALS_APPROVED,
  SCORECARD_STATUS_GOALS_PREPARED,
  SCORECARD_STATUS_READY_FOR_REVIEW,
} from 'constants/scorecards';
import { useIntl } from 'react-intl';
import { ScorecardStatus } from 'store/interfaces';

export default function useScorecardStatusTooltipMessage(
  status: ScorecardStatus,
) {
  const { formatMessage } = useIntl();
  switch (status) {
    case SCORECARD_STATUS_ACTIVE:
      return formatMessage({
        id: 'pages.scorecardLabelText.active',
        defaultMessage:
          'Employee shall personalize Scorecard by adding custom goals, align and approve the Scorecard structure and details with People Manager.',
      });
    case SCORECARD_STATUS_FEEDBACK_REQUESTED:
      return formatMessage({
        id: 'pages.scorecardLabelText.feedbackRequested',
        defaultMessage:
          'Reviewers shall provide assessment and feedback of the Employee. The People Manager can support selected Scorecard adjustments if needed. Employee neither can adjust the Scorecard nor see the assessment and feedback yet.',
      });

    case SCORECARD_STATUS_FEEDBACK_COLLECTED:
      return formatMessage({
        id: 'pages.scorecardLabelText.feedbackCollected',
        defaultMessage:
          'The People Manager shall review feedback and assessment as it is gathered from all Reviewers already. He/she shall make necessary adjustments and share the results with the Employee for joint review.',
      });

    case SCORECARD_STATUS_READY_FOR_REVIEW:
      return formatMessage({
        id: 'pages.scorecardLabelText.readyForReview',
        defaultMessage:
          'The People Manager and Employee shall review results of the Performance Review in a dedicated meeting and correct potential mistakes. Once done, the People Manager shall turn the Scorecard to final Completed state.',
      });

    case SCORECARD_STATUS_COMPLETED:
      return formatMessage({
        id: 'pages.scorecardLabelText.completed',
        defaultMessage:
          'The Performance Review Process is completed for this Employee. The People Manager and Employee have already reviewed the results. There shall be no further adjustment to the Scorecard needed.',
      });

    case SCORECARD_STATUS_GOALS_PREPARED:
      return formatMessage({
        id: 'pages.scorecardLabelText.goalsPrepared',
        defaultMessage:
          'Scorecard goals confirmed by Employee with review from People Manager requested. People Manager shall review and approve Scorecard goals for the Employee to start working toward them in the current Review Cycle.',
      });
    case SCORECARD_STATUS_GOALS_APPROVED:
      return formatMessage({
        id: 'pages.scorecardLabelText.goalsApproved',
        defaultMessage:
          'Scorecard goals are aligned and confirmed between Employee and People Manager. An employee shall work toward the goals during the current Review Cycle, with a performance review conducted at the end. Employee can update goal reviewers and request changes to the goals structure and details from People Manager.',
      });
    case SCORECARD_STATUS_DELETED:
      return formatMessage({
        id: 'pages.scorecardLabelText.deleted',
        defaultMessage:
          'This Scorecard is deleted. You can restore it back so user can proceed with further updates.',
      });

    default:
      return null;
  }
}
