import {
  OkrObjectiveKeyResultResponse,
  OkrObjectiveResponse,
} from 'store/okrObjectives';
import { UserMgmtRole } from '../userMgmt/interfaces';
import { User } from './User';

export enum ScorecardAuditAction {
  CREATED_FROM_TEMPLATE = 'scorecard:created:from_template',

  UPDATED = 'scorecard:updated',

  GOALS_CONFIRMED = 'scorecard:action:goals:confirmed',
  GOALS_APPROVED = 'scorecard:action:goals:approved',
  GOALS_REDRAFTED = 'scorecard:action:goals:redrafted',
  GOALS_REVERTED_TO_ACTIVE = 'scorecard:action:goals:in-progress:reverted',

  ASSESSMENT_REQUESTED = 'scorecard:action:assessment:requested',
  ASSESSMENT_REVIEW = 'scorecard:action:assessment:review',
  ASSESSMENT_COLLECTED = 'scorecard:action:assessment:collected',
  ASSESSMENT_RE_REQUESTED = 'scorecard:action:assessment:re-requested',
  ASSESSMENT_COMPLETED = 'scorecard:action:assessment:completed',

  CUSAT_PROJECT_ASSIGNED = 'cusat:allocated_projects:assigned',
  CUSAT_PROJECT_UNASSIGNED = 'cusat:allocated_projects:unassigned',

  FEEDBACK_REQUEST_BULK_UPDATED = 'sc-feedback-request:updated:bulk',

  FEEDBACK_MODERATED = 'sc-feedback:moderated',
  FEEDBACK_UPDATED = 'sc-feedback:updated',
  FEEDBACK_PROVIDED = 'sc-feedback:provided',
}

export enum UserManagementAction {
  USER_ROLES_UPDATED = 'user:assigned-roles:updated',
  ADDITIONAL_SCORECARD_ACCESS_UPDATED = 'user:additional-scorecard-access:updated',
  ADDITIONAL_SCORECARD_ACCESS_REMOVED = 'user:additional-scorecard-access:removed',
}

export enum OkrObjectiveAction {
  CREATED = 'okr:objective:created',
  UPDATED = 'okr:objective:updated',
  AGREED_ATTAINMENT_UPD = 'okr:objective:updated:agreed-attainment',
  USER_ACCEPTED_KR = 'okr:objective-binding:child:child-binding:request-accepted',
  USER_DECLINED_KR = 'okr:objective-binding:child:child-binding:request-declined',
  USER_UNLINKED_OBJ = 'okr:objective-binding:child:established-binding:unlinked',
  USER_LINKED_KR = 'okr:objective-binding:parent:child-binding:requested',
  USER_UNASSIGNED_KR = 'okr:objective-binding:parent:established-binding:unlinked',
  USER_ASSIGN_CANCEL_KR = 'okr:objective-binding:parent:child-binding:cancelled',
  AUTO_UPDATE_ATTAINMENT = 'okr:objective:auto-updated:attainment',
}

export interface ChangeGroup {
  dataChangePayload?: Record<string, any>;
  previousState?: Record<string, any>;
}

export interface ActionType {
  alias:
    | ScorecardAuditAction.UPDATED
    | ScorecardAuditAction.CREATED_FROM_TEMPLATE
    | ScorecardAuditAction.GOALS_APPROVED
    | ScorecardAuditAction.ASSESSMENT_REQUESTED
    | ScorecardAuditAction.ASSESSMENT_REVIEW
    | ScorecardAuditAction.GOALS_CONFIRMED
    | ScorecardAuditAction.GOALS_REDRAFTED
    | ScorecardAuditAction.GOALS_REVERTED_TO_ACTIVE
    | ScorecardAuditAction.ASSESSMENT_COMPLETED
    | ScorecardAuditAction.CUSAT_PROJECT_ASSIGNED
    | ScorecardAuditAction.CUSAT_PROJECT_UNASSIGNED
    | ScorecardAuditAction.FEEDBACK_REQUEST_BULK_UPDATED
    | ScorecardAuditAction.FEEDBACK_MODERATED
    | ScorecardAuditAction.FEEDBACK_UPDATED
    | ScorecardAuditAction.FEEDBACK_PROVIDED
    | ScorecardAuditAction.ASSESSMENT_COLLECTED
    | ScorecardAuditAction.ASSESSMENT_RE_REQUESTED
    | UserManagementAction.USER_ROLES_UPDATED
    | UserManagementAction.ADDITIONAL_SCORECARD_ACCESS_UPDATED
    | UserManagementAction.ADDITIONAL_SCORECARD_ACCESS_REMOVED
    | OkrObjectiveAction.CREATED
    | OkrObjectiveAction.UPDATED
    | OkrObjectiveAction.AGREED_ATTAINMENT_UPD
    | OkrObjectiveAction.USER_ACCEPTED_KR
    | OkrObjectiveAction.USER_DECLINED_KR
    | OkrObjectiveAction.USER_UNLINKED_OBJ
    | OkrObjectiveAction.USER_UNASSIGNED_KR
    | OkrObjectiveAction.USER_ASSIGN_CANCEL_KR
    | OkrObjectiveAction.AUTO_UPDATE_ATTAINMENT
    | OkrObjectiveAction.USER_LINKED_KR;
  description: string;
  id: number;
}

export type ChangelogUser = Pick<User, 'avatar' | 'fullName' | 'id'>;

export interface Changelog {
  doneAt: string;
  actor: ChangelogUser;
  actionType: ActionType;
  changeGroups: ChangeGroup[];
}

export interface UMChangelog extends Changelog {
  role: UserMgmtRole;
  user: ChangelogUser;
}

export interface SubgoalChangeDetails {
  weight?: number;
  name?: string;
  description?: string;
  assessmentScaleGroup?: { name: string };
}

export interface GoalChangeDetails {
  weight?: number;
  name?: string;
  description?: string;
  subgoals?: SubgoalChange[];
  extraMultiplier?: { name: string };
  params?: GoalParam[];
  type?: string;
  referenceValue?: string;
  resultValue?: string;
  targetValue?: string;
}

export interface GoalParam {
  id: number;
  name: string;
  previousState: {
    value: boolean;
  };
  payload: {
    value: boolean;
  };
  type: string;
}
export interface SubgoalChange {
  id: number;
  name: string;
  type: string;
  payload: SubgoalChangeDetails;
  previousState: SubgoalChangeDetails;
}

export interface GoalChange {
  id: number;
  name: string;
  type: string;
  payload: GoalChangeDetails;
  previousState: GoalChangeDetails;
}

export interface ParamsChange {
  payload: GoalChangeDetails;
  previousState: GoalChangeDetails;
}

export enum SelfAssessmentEnum {
  INCLUDE = 'Include self-assessment',
  ENABLE = 'Enable self-assessment',
}

export type OkrKeyResultChange = {
  id: number;
  name: string;
  payload: OkrObjectiveKeyResultResponse;
  type: string;
};

export type OkrKeyResultChangeMerged = OkrKeyResultChange & {
  previousState: OkrObjectiveKeyResultResponse;
};

export interface KeyResult {
  id: number;
  name: string;
  payload: OkrObjectiveKeyResultResponse;
  type: string;
}

export type OkrObjectiveChangeRaw = Omit<OkrObjectiveResponse, 'keyResults'> & {
  keyResults?: KeyResult[];
  type?: string;
  childObjective: Omit<OkrObjectiveResponse, 'keyResults'>;
  childTargetUser: User;
  parentKeyResult: OkrObjectiveKeyResultResponse;
  parentTargetUser: User;
  status: string;
};

export type OkrObjectiveChangeMerged = OkrObjectiveChangeRaw & {
  keyResults: OkrKeyResultChangeMerged[];
  previousState: Omit<OkrObjectiveChangeMerged, 'keyResults'>;
};

export interface OkrChangeGroup {
  dataChangePayload: OkrObjectiveChangeRaw;
  previousState: OkrObjectiveChangeRaw;
}
