import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { errorHandlingMiddleware } from 'store/middlewares';
import { save, load } from 'redux-localstorage-simple';
import {
  REDUX_STATE_SAVE_TO_LOCALSTORAGE,
  REDUX_STATE_NAMESPACE,
} from 'constants/reduxLocalstorage';
import { cloneDeep } from 'lodash-es';
import reviewCyclesReducer from './reviewCycles';
import profileReducer from './profile';
import toastReducer from './toast';
import optionsReducer from './options';
import directReportScorecardsReducer from './scorecards/direct';
import subordinateScorecardsReducer from './scorecards/subordinates';
import ownScorecardsReducer from './scorecards/own';
import sharedScorecardsReducer from './scorecards/shared';
import feedbackRequestsReducer from './feedbackRequests';
import commonGoalsReducer from './commonGoals';
import departmentReducer from './scorecards/department';
import summaryReducer from './summary';
import uiStateReducer from './uiState';
import roadmapReducer from './roadmap';
import userManagementReducer from './userMgmt';
import optimisticLockingReducer from './optimisticLocking';
import okrReviewCyclesReducer from './okrReviewCycles';
import okrObjectivesReducer from './okrObjectives';
import okrMyTeamReducer from './okrMyTeam';
import okrTeamOverviewReducer from './okrTeamOverview';
import okrPendingKeyResultsReducer from './okrPendingKeyResults';
import commentsReducer from './comments';
import { directReportScorecardInitialState } from './scorecards/direct/config';
import { subordinateScorecardInitialState } from './scorecards/subordinates/config';
import { departmentInitialState } from './scorecards/department/config';
import { ownScorecardInitialState } from './scorecards/own/config';
import { okrMyTeamInitialState } from './okrMyTeam/config';
import { okrObjectivesInitialState } from './okrObjectives/config';
import { okrTeamOverviewInitialState } from './okrTeamOverview/config';
import { sharedScorecardInitialState } from './scorecards/shared/config';

export const rootReducer = combineReducers({
  reviewCycles: reviewCyclesReducer,
  profile: profileReducer,
  toast: toastReducer,
  options: optionsReducer,
  feedbackRequests: feedbackRequestsReducer,
  commonGoals: commonGoalsReducer,
  scorecardsOwn: ownScorecardsReducer,
  scorecardsDirect: directReportScorecardsReducer,
  scorecardsSubordinates: subordinateScorecardsReducer,
  scorecardsDepartment: departmentReducer,
  summary: summaryReducer,
  uiState: uiStateReducer,
  roadmap: roadmapReducer,
  userManagement: userManagementReducer,
  optimisticLocking: optimisticLockingReducer,
  okrReviewCycles: okrReviewCyclesReducer,
  okrObjectives: okrObjectivesReducer,
  okrMyTeam: okrMyTeamReducer,
  okrTeamOverview: okrTeamOverviewReducer,
  okrPendingKeyResults: okrPendingKeyResultsReducer,
  comments: commentsReducer,
  scorecardsShared: sharedScorecardsReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        save({
          namespace: REDUX_STATE_NAMESPACE,
          states: REDUX_STATE_SAVE_TO_LOCALSTORAGE,
        }),
        errorHandlingMiddleware,
      ),
    preloadedState,
  });

const store = setupStore(
  load({
    namespace: REDUX_STATE_NAMESPACE,
    states: REDUX_STATE_SAVE_TO_LOCALSTORAGE,
    preloadedState: {
      scorecardsDirect: cloneDeep(directReportScorecardInitialState),
      scorecardsSubordinates: cloneDeep(subordinateScorecardInitialState),
      scorecardsOwn: cloneDeep(ownScorecardInitialState),
      scorecardsDepartment: cloneDeep(departmentInitialState),
      scorecardsShared: cloneDeep(sharedScorecardInitialState),
      okrMyTeam: cloneDeep(okrMyTeamInitialState),
      okrObjectives: cloneDeep(okrObjectivesInitialState),
      okrTeamOverview: cloneDeep(okrTeamOverviewInitialState),
    },
    disableWarnings: true,
  }),
);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
