import { styled } from '@mui/material/styles';
import { Button as MuiButton, Grid, Typography } from '@mui/material';

export const StyledButton = styled(MuiButton)({
  padding: '12px 12px',
  width: '100%',
  fontSize: '16px',
});

export const StyledResetButton = styled(Grid)({
  width: '100%',

  '> a': {
    width: '100%',
  },
});

export const ButtonContainer = styled('div')({
  paddingTop: '15px',
  paddingBottom: '10px',
});

export const StyledResetLink = styled(Grid)({
  textAlign: 'center',
});

export const StyledMessage = styled(Typography)({
  marginTop: '20px',
  marginBottom: '-15px',
});
