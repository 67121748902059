import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserMgmtUserOption } from 'store/interfaces';
import api from 'api';
import { API_USER_MANAGEMENT_USER_OPTIONS } from 'constants/api';
import { storeName } from '../config';

export const updateUsersOptions = createAsyncThunk<UserMgmtUserOption[]>(
  `${storeName}/updateUsersOptions`,
  () =>
    api.axios
      .get(API_USER_MANAGEMENT_USER_OPTIONS)
      .then((res) => res.data?.list || [])
      .catch(() => [])
      .then((res) => res),
);
