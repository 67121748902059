import { ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';
import { ScorecardStores, ScorecardFilters } from 'store/interfaces';
import { DepartmentState } from './department';
import { SubordinateState } from './subordinates';
import { DirectReportState } from './direct';
import { OwnScorecardsState } from './own';
import { SharedState } from './shared';
import {
  changeFilter,
  changePage,
  changeStatus,
  setFullData,
  updateFullData,
  clearFilters,
  updateList,
  moderateFeedback,
  patchScorecard,
} from './actions';

import { storeName as sharedStoreName } from './shared/config';

type ScorecardStates =
  | DepartmentState
  | SubordinateState
  | DirectReportState
  | OwnScorecardsState
  | SharedState;

export default function addScorecardsCases<T extends ScorecardStates>(
  builder: ActionReducerMapBuilder<T>,
  storeName: ScorecardStores,
  initialFilters: ScorecardFilters,
) {
  return builder
    .addCase(
      updateList.pending,
      (
        state,
        {
          meta: {
            arg: { store },
          },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          state.loading = true;
        }
      },
    )
    .addCase(
      updateList.fulfilled,
      (
        state,
        {
          meta: {
            arg: { store },
          },
          payload: { list, pagination },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          if (!state.init && list.length > 0 && storeName === sharedStoreName) {
            (state as SharedState).hasSharedScorecards = true;
          }

          Object.assign(state, {
            items: list,
            pagination,
            loading: false,
            init: true,
          });

          list.forEach((item) => {
            if (state.fullData[item.id]) {
              Object.assign(state.fullData[item.id], {
                ...state.fullData[item.id],
                data: {
                  ...(state.fullData[item.id]?.data || {}),
                  ...item,
                },
              });
            }
          });
        }
      },
    )
    .addCase(
      updateList.rejected,
      (
        state,
        {
          meta: {
            arg: { store },
          },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          state.loading = false;
        }
      },
    )
    .addCase(setFullData, (state, { payload: { store, data } }) => {
      if (store.indexOf(storeName) > -1) {
        const listItem = state.items.find((item) => item.id === data.id);
        if (listItem) {
          const { goals, feedbacks, feedbackRequests, ...listData } = data;
          Object.assign(listItem, listData);
        }
        state.fullData[data.id].data = data;
      }
    })
    .addCase(clearFilters, (state, { payload: { store, data } }) => {
      if (store.indexOf(storeName) > -1) {
        const newFilters = { ...initialFilters };
        if (data) {
          data.forEach((filterName) => {
            if (Object.hasOwn(newFilters, filterName)) {
              // @ts-ignore
              delete newFilters[filterName];
            }
          });
        }
        Object.assign(state.filters, newFilters);
      }
    })
    .addCase(changeFilter, (state, { payload: { store, data } }) => {
      if (store.indexOf(storeName) > -1) {
        if ('key' in data && 'value' in data) {
          Object.assign(state, {
            filters: { ...state.filters, [data.key]: data.value },
            init: true,
          });
        } else {
          Object.assign(state, {
            filters: { ...state.filters, ...data },
            init: true,
          });
        }
      }
    })
    .addCase(changePage, (state, { payload: { store, page } }) => {
      if (store.indexOf(storeName) > -1) {
        state.pagination.page = page;
      }
    })
    .addMatcher(
      isAnyOf(
        updateFullData.pending,
        changeStatus.pending,
        moderateFeedback.pending,
        patchScorecard.pending,
      ),
      (
        state,
        {
          meta: {
            arg: { id, store },
          },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          if (!state.fullData[id]) {
            state.fullData[id] = { loading: true, data: null };
          } else {
            state.fullData[id].loading = true;
          }
        }
      },
    )
    .addMatcher(
      isAnyOf(
        updateFullData.fulfilled,
        changeStatus.fulfilled,
        moderateFeedback.fulfilled,
        patchScorecard.fulfilled,
      ),
      (
        state,
        {
          meta: {
            arg: { id, store },
          },
          payload,
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          const listItem = state.items.find((item) => item.id === id);
          if (listItem) {
            const { goals, feedbacks, feedbackRequests, ...listData } = payload;
            Object.assign(listItem, { ...listData, locked: false });
          }
          state.fullData[id] = {
            loading: false,
            data: payload,
          };
        }
      },
    )
    .addMatcher(
      isAnyOf(
        updateFullData.rejected,
        changeStatus.rejected,
        moderateFeedback.rejected,
        patchScorecard.rejected,
      ),
      (
        state,
        {
          meta: {
            arg: { id, store },
          },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          state.fullData[id].loading = false;
        }
      },
    )
    .addMatcher(
      isAnyOf(patchScorecard.rejected),
      (
        state,
        {
          meta: {
            arg: { id, store },
          },
        },
      ) => {
        if (store.indexOf(storeName) > -1) {
          const listItem = state.items.find((item) => item.id === id);
          if (listItem) {
            Object.assign(listItem, { ...listItem, locked: true });
          }
        }
      },
    );
}
