import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.userManagement.title',
    defaultMessage: 'User Management',
  },
  filterByRole: {
    id: 'pages.userManagement.filterByRole',
    defaultMessage: 'Filter by role...',
  },
  name: {
    id: 'pages.userManagement.name',
    defaultMessage: 'Name',
  },
  rolesAssigned: {
    id: 'pages.userManagement.rolesAssigned',
    defaultMessage: 'Roles and Access Rules',
  },
  allRoles: {
    id: 'pages.userManagement.allRoles',
    defaultMessage: 'All roles',
  },
  availableDepartments: {
    id: 'pages.userManagement.availableDepartments',
    defaultMessage: 'Available departments:',
  },
  availableCountries: {
    id: 'pages.userManagement.AvailableCountries',
    defaultMessage: 'Available locations:',
  },
  roles: {
    id: 'pages.userManagement.roles',
    defaultMessage: 'Roles:',
  },
  additionalScorecardAccess: {
    id: 'pages.userManagement.additionalScorecardAccess',
    defaultMessage: 'Additional scorecard access:',
  },
  reviewCycles: {
    id: 'pages.userManagement.reviewCycles',
    defaultMessage: 'Review cycles - <b>{cycles}</b>',
  },
  users: {
    id: 'pages.userManagement.users',
    defaultMessage: 'Users - <b>{users}</b>',
  },
});
