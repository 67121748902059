import { createAsyncThunk } from '@reduxjs/toolkit';
import { LastChange, Pagination, UserMgmtUserOption } from 'store/interfaces';
import api from 'api';
import { API_USER_MANAGEMENT_USER_OPTIONS } from 'constants/api';
import { storeName } from '../config';

export const getUsersOptions = createAsyncThunk<{
  list: UserMgmtUserOption[];
  pagination: Pagination;
  lastChange: LastChange;
}>(`${storeName}/userOptionsList`, async (params, thunkAPI) =>
  api.axios
    .get(API_USER_MANAGEMENT_USER_OPTIONS)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
