import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_USER_MANAGEMENT_UPDATE_ADDITIONAL_SCORECARD_ACCESS } from 'constants/api';
import { storeName } from '../config';
import { UpdateRolesResponse } from '../interfaces/UpdateRolesResponse';
import { getUsers } from './getUsers';

interface UpdateAdditionalScorecardAccessBody {
  userId: number;
  reviewCycleIds: number[];
  targetUserIds: number[];
}

export const updateAdditionalScorecardAccess = createAsyncThunk<
  UpdateRolesResponse,
  UpdateAdditionalScorecardAccessBody
>(`${storeName}/updateAdditionalScorecardAccess`, async (params, thunkAPI) =>
  api.axios
    .post<UpdateRolesResponse>(
      API_USER_MANAGEMENT_UPDATE_ADDITIONAL_SCORECARD_ACCESS,
      params,
    )
    .then((res) => {
      thunkAPI.dispatch(getUsers({ loadMore: false }));
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
