import { SharedState, SharedFilters } from './interfaces';

export const initialFilters: SharedFilters = {
  reviewCycleIds: null,
  userName: '',
  careerMentorIds: null,
  scorecardStatuses: null,
  sharedScorecards: true,
};

export const sharedScorecardInitialState: SharedState = {
  items: [],
  loading: false,
  filters: { ...initialFilters },
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
  init: false,
  fullData: {},
  careerMentorOptions: [],
  hasSharedScorecards: false,
};

export const storeName = 'scorecardsShared' as const;

export type SharedStoreName = typeof storeName;
