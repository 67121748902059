// Route prefixes
export const OKR_ROUTE = 'okr';

// Main routes
export const URL_REVIEW_CYCLES = 'review-cycles';
export const URL_MY_SCORECARDS = 'my-scorecards';
export const URL_MY_TEAM = 'my-team';
export const URL_FEEDBACK_REQUESTS = 'feedback-requests';
export const URL_DEPARTMENT_OVERVIEW = 'department-overview';
export const URL_TEAM_OVERVIEW = 'team-overview';
export const URL_CAREER_ROADMAP = 'roadmap';
export const URL_CAREER_ROADMAP_SELECT = '';
export const URL_CAREER_ROADMAP_DEPARTMENT = ':departmentId';
export const URL_USER_MANAGEMENT = 'user-management';
export const URL_MY_OKR = `${OKR_ROUTE}/my-okr`;
export const URL_MY_TEAM_OKR = `${OKR_ROUTE}/my-team`;
export const URL_OKR_SETTING = `${OKR_ROUTE}/settings`;
export const URL_OKR_TEAM_OVERVIEW = `${OKR_ROUTE}/${URL_TEAM_OVERVIEW}`;

export const URL_RESET_PASSWORD = 'reset-password';

// Sub routes
export const URL_MY_TEAM_SUBORDINATES = `subordinates`;
export const URL_MY_TEAM_DIRECT_REPORTS = `direct-reports`;
export const URL_MY_TEAM_INACTIVE_EMPLOYEES = `inactive-employees`;
export const URL_MY_TEAM_DELETED_SCORECARDS = `deleted-scorecards`;
export const URL_MY_TEAM_SHARED_SCORECARDS = `shared-scorecards`;

export const URL_SCORECARD_TEMPLATES = 'scorecard-templates';

export const URL_CREATE_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/create`;
export const URL_EDIT_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/:templateId/edit`;

export const URL_EDIT_SCORECARD = ':scorecardId/edit';

export const URL_LIST = 'list';

export const URL_ACTUAL = 'actual';
export const URL_HISTORY = 'history';

export const URL_COMMON_GOALS = 'common-goals';

export const URL_OBJECTIVES = 'objectives';
export const URL_CREATE_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/create`;
export const URL_EDIT_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/:id/edit`;
export const URL_VIEW_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/:id`;
export const URL_VIEW_USER_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/user/:userId/:id`;
export const URL_CREATE_USER_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/user/:userId/create`;
export const URL_EDIT_USER_OBJECTIVE = `${URL_OBJECTIVES}/:reportingPeriod/user/:userId/:id/edit`;
