import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy } from 'react';
import AuthContainer from 'components/AuthContainer';
import { saveLoginRedirect } from 'helpers';
import { API_LOGIN_SAML } from 'constants/api';
import { StyledButton, ButtonContainer, StyledMessage } from './SignIn.styled';

const UniversalLogInForm = lazy(() => import('./UniversalLogInForm.component'));

export default function SignIn() {
  return (
    <AuthContainer>
      <>
        <Typography variant="h5">
          <FormattedMessage id="pages.signIn.header" defaultMessage="Sign in" />
        </Typography>
        <ButtonContainer>
          <StyledButton
            onClick={saveLoginRedirect}
            variant="contained"
            href={API_LOGIN_SAML}
            color="secondary"
          >
            <FormattedMessage
              id="pages.signIn.button"
              defaultMessage="Sign in with Star Google account"
            />
          </StyledButton>
        </ButtonContainer>
        <StyledMessage>
          <FormattedMessage
            id="pages.signIn.message"
            defaultMessage="or Sign in via Email/Password as an <b>External user</b>"
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        </StyledMessage>
        <UniversalLogInForm />
      </>
    </AuthContainer>
  );
}
